import _ from "lodash";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/ENGLISH-White-CAN-PCC-Logo.png";
import logoFR from "../assets/french-can-pcc-logo-white.png";
import { useShowBanner } from "../lib/custom_hooks";
import HamburgerMenu from "./common/HamburgerMenu";
import LanguageSwitcher from "./common/LanguageSwitcher";

interface ILinkWithTooltip {
  linkId: string;
  activeRoute: string | undefined;
}

const LinkWithTooltip: FC<ILinkWithTooltip> = ({ linkId, activeRoute }) => {
  const { t } = useTranslation();
  const navLinkClasses = useMemo(() => {
    return `top-nav-link text-white mr-4 py-1 ${
      linkId === "grid" || linkId === "plain-language-recommendations"
        ? "tailwind-hidden md:inline-block"
        : "inline-block"
    } ${activeRoute === linkId ? "active" : null}`;
  }, [activeRoute, linkId]);
  return (
    <>
      <Link data-tip="" className={navLinkClasses} to={`/${linkId}`} data-for={linkId}>
        {t(`recommendations.${linkId}`)}
      </Link>
    </>
  );
};

const Header = () => {
  const { t, i18n } = useTranslation();

  const location = useLocation();

  const activeRoute = useMemo(
    () => _.first(_.split(_.trimStart(location.pathname, "/"), "/")),
    [location.pathname]
  );

  const withBanner = useShowBanner();

  return (
    <header className="z-10 relative">
      {withBanner && (
        <div
          className="webinar-info"
          dangerouslySetInnerHTML={{ __html: t("app.decemberInfo") }}
        ></div>
      )}
      <div className="h-12 p-2 sm:h-15 flex flex-row items-center shadow bg-red-200">
        <div className="logo flex-grow lg:flex-grow-0 flex flex-row">
          {location.pathname !== "/" && (
            <Link to="/" className="mobile-back-link block sm:hidden" />
          )}
          <Link className="flex flex-row items-center" to="/">
            <img
              src={i18n.language === "fr" ? logoFR : logo}
              className="h-12 tailwind-hidden sm:block"
              alt="logo"
            />
            <img
              src={i18n.language === "fr" ? logoFR : logo}
              className="h-8 block sm:hidden"
              alt="logo"
            />
          </Link>
        </div>
        <div className="flex flex-row items-center ml-auto">
          <div className="mr-16">
            <nav>
              <LinkWithTooltip linkId="grid" activeRoute={activeRoute} />
              <LinkWithTooltip linkId="recommendations" activeRoute={activeRoute} />
              <LinkWithTooltip linkId="plain-language-recommendations" activeRoute={activeRoute} />
              <LanguageSwitcher />
            </nav>
          </div>
          <HamburgerMenu />
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {};

export default Header;
